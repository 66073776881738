import React from "react";
import { Link } from "react-scroll";

function Footer() {
    return (
        <>

            <div className="border-t-2 border-b-2 border-white mx-auto py-4 sm:py-20">
                <div className="max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-2xl mx-auto flex max-lg:flex-col">
                <div className="max-lg:w-full w-1/3  px-4">
                    <h1 className="text-white text-2xl font-bold font-press-start">MIKE</h1>
                    <p className="text-white font-press-start-lato opacity-50 sm:pr-10 mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
                </div>
                <div className="max-lg:w-full w-1/3 flex  px-4 md:mt-10">
                    <div className="w-1/2">
                        <ul className="flex flex-col space-y-2">
                            <li>
                                <Link className="Menu__Link" to="Home__First" spy={true} smooth={true} offset={50} duration={500}>HOME</Link>
                            </li>
                            <li>
                                <Link className="Menu__Link" to="Home__Service" spy={true} smooth={true} offset={50} duration={500}>SERVICE</Link>
                            </li>
                            <li>
                                <Link className="Menu__Link" to="Home__Opinion" spy={true} smooth={true} offset={50} duration={500}>OPINION</Link>
                            </li>
                            <li>
                                <Link className="Menu__Link" to="Home__Portfolio" spy={true} smooth={true} offset={50} duration={500}>PORTFOLIO</Link>
                            </li>
                            <li>
                                <Link className="Menu__Link" to="Home__Gallery" spy={true} smooth={true} offset={50} duration={500}>GALLERY</Link>
                            </li>
                            <li>
                                <Link className="Menu__Link" to="Home__Team" spy={true} smooth={true} offset={50} duration={500}>TEAM</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="w-1/2 text-white">
                        <ul className="flex flex-col space-y-2">
                            <li className="flex">
                                <a href="" className="flex justify-center items-center">
                                    <div className="mr-3 w-8 h-8 border flex justify-center items-center relative group overflow-hidden cursor-pointer">
                                        <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e8098f75d770fa12ea3e6e_Vector.svg'} className={'absolute transition-all  duration-300 translate-y-0 group-hover:-translate-y-6'} />
                                        <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e8098f75d770fa12ea3e6e_Vector.svg'} className={'opacity-50 absolute translate-y-6 transition-all  duration-300 group-hover:translate-y-0'} />
                                    </div>
                                    <h1>Name Lastname</h1>
                                </a>
                            </li>
                            <li className="flex">
                                <a href="" className="flex justify-center items-center">
                                    <div className="mr-3 w-8 h-8 border flex justify-center items-center relative group overflow-hidden cursor-pointer">
                                        <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e8098f75d7705b5eea3e6f_5fc2d6c4e509f31bddd6ec13_5fb410854861edca9a030b2e_Group%209%201%201.svg'} className={'absolute transition-all  duration-300 translate-y-0 group-hover:-translate-y-6'} />
                                        <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e8098f75d7705b5eea3e6f_5fc2d6c4e509f31bddd6ec13_5fb410854861edca9a030b2e_Group%209%201%201.svg'} className={'opacity-50 absolute translate-y-6 transition-all  duration-300 group-hover:translate-y-0'} />
                                    </div>
                                    <h1>Name Lastname</h1>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="max-lg:w-full max-lg:pb-12 w-1/3 ">
                    <h1 className="text-white text-2xl font-bold font-press-start text-center">CALL ME</h1>
                    <div className="w-fit  mx-auto mt-10 group">
                        <a
                           href="tel:+37490000000" 
                           className="text-white px-14 py-4 transition-all duration-300 after:transition-all after:duration-300 relative after:absolute after:w-full after:h-full after:right-0 after:bg-[#d3463f] after:-top-1 before:w-0 before:transition-all before:duration-300 before:delay-300 before:h-1 before:bg-white before:left-0 before:bottom-0 before:absolute  hover:after:w-0 hover:before:w-full">
                           <span className="relative z-10">+37490000000</span></a>
                    </div>
                </div>
                </div>
              
            </div>

        </>
    )
}


export default Footer