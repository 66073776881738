window.onscroll = function() {
    var theta = document.documentElement.scrollTop / 2500 % Math.PI;
    console.log(theta)

document.getElementById('js-logo').style.transform ='translate(-' + theta*200 + 'px)';
document.getElementById('js-logo1').style.transform ='translate(' + theta*200 + 'px)';
document.getElementById('js-logo2').style.transform ='translate(-' + theta*200 + 'px)';
document.getElementById('js-logo3').style.transform ='translate(' + theta*200 + 'px)';
document.getElementById('js-logo4').style.transform ='translate(-' + theta*200 + 'px)';
document.getElementById('js-logo5').style.transform ='translate(' + theta*200 + 'px)';
}

