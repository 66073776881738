import React from "react";
import Slider from "react-slick";
function HomeTeam() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {

                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {

                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {

                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },

        ]
    };
    return (
        <>
            <div className="max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-2xl   mx-auto py-20" id="Home__Team">
                <Slider {...settings}>
                    <div className="">
                        <div className="max-sm:mx-auto max-sm:w-[260px] 2xl:w-[361px] 2xl:h-[391px] p-4 relative group  cursor-pointer overflow-hidden border">
                            <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61ea6fd25724fb0484e61605_pexels-photo-9466721%201.jpg'} className={'w-full h-full object-cover transition-all duration-1000  group-hover:scale-105'} />
                            <div className="absolute bottom-4 transition-all duration-300 opacity-0 group-hover:opacity-100 px-8 border">
                                <h1 className="text-white font-press-start-cardo">Deer Fielding</h1>
                                <p className="text-white font-press-start-lato opacity-50">FOUNDER</p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="max-sm:mx-auto max-sm:w-[260px] 2xl:w-[361px] 2xl:h-[391px] p-4 relative group  cursor-pointer overflow-hidden border">
                            <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61ea6fea53186b2aecedf476_pexels-photo-9769874%201.jpg'} className={'w-full h-full object-cover transition-all duration-1000  group-hover:scale-105'} />
                            <div className="absolute bottom-4 transition-all duration-300 opacity-0 group-hover:opacity-100 px-8 border">
                                <h1 className="text-white font-press-start-cardo">Name Lastname</h1>
                                <p className="text-white font-press-start-lato opacity-50">Actor</p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="max-sm:mx-auto max-sm:w-[260px] 2xl:w-[361px] 2xl:h-[391px] p-4 relative group  cursor-pointer overflow-hidden border">
                            <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61ea6fd25724fb0484e61605_pexels-photo-9466721%201.jpg'} className={'w-full h-full object-cover transition-all duration-1000  group-hover:scale-105'} />
                            <div className="absolute bottom-4 transition-all duration-300 opacity-0 group-hover:opacity-100 px-8 border">
                                <h1 className="text-white font-press-start-cardo">Gloria Hibs</h1>
                                <p className="text-white font-press-start-lato opacity-50">Head of People</p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="max-sm:mx-auto max-sm:w-[260px] 2xl:w-[361px] 2xl:h-[391px] p-4 relative group  cursor-pointer overflow-hidden border">
                            <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61ea6ffa196569531d59411a_pexels-photo-9771525.jpg'} className={'w-full h-full object-cover transition-all duration-1000  group-hover:scale-105'} />
                            <div className="absolute bottom-4 transition-all duration-300 opacity-0 group-hover:opacity-100 px-8 border">
                                <h1 className="text-white font-press-start-cardo">Mac Philips</h1>
                                <p className="text-white font-press-start-lato opacity-50">CORDINATOR</p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="max-sm:mx-auto max-sm:w-[260px] 2xl:w-[361px] 2xl:h-[391px] p-4 relative group  cursor-pointer overflow-hidden border">
                            <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61ea6ff2246be67d7ff9b19b_pexels-photo-9771504.jpg'} className={'w-full h-full object-cover transition-all duration-1000  group-hover:scale-105'} />
                            <div className="absolute bottom-4 transition-all duration-300 opacity-0 group-hover:opacity-100 px-8 border">
                                <h1 className="text-white font-press-start-cardo">JAY TOMS</h1>
                                <p className="text-white font-press-start-lato opacity-50">CTO</p>
                            </div>
                        </div>
                    </div>
                 
                </Slider>
            </div>
        </>
    )
}
export default HomeTeam