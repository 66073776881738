import React from "react";
import Slider from "react-slick";


function HomeOpinion() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    arrows: false
                }
            },

        ]
    };
    return (
        <>
            <div className="md:px-10 py-20" id="Home__Opinion">
                <Slider {...settings}>
                    <div className="">
                        <div className="flex justify-center">
                            <div className="w-[80%] md:w-[50%]  md:mx-auto text-center py-10 flex flex-col">
                                <p className="text-white text-sm md:text-xl font-press-start-lato my-10">
                                    Working with Micky is a pleasure. We are impressed by the way how their teamwork. It’s professional, at a good pace with great communication about our business needs. They were prepared extremely well to work with our company.
                                </p>
                                <div className="rounded-full h-24 w-24 overflow-hidden mx-auto">
                                    <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/6200e05af66f015f8d72784a_pexels-photo-9769874%201-p-500.jpeg'} className={'w-full h-full object-cover'} />
                                </div>
                                <p className="text-white text-xl font-press-start mt-3">JESSY
                                    JAMES</p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="flex justify-end max-md:pr-14">
                            <div className="w-[80%] md:w-[50%]  md:mx-auto text-center py-10 flex flex-col">
                                <p className="text-white text-sm md:text-xl font-press-start-lato my-10">
                                    Turpis phasellus mi vulputate lacinia in auctor egestas eget duis. Facilisi eget ac purus pellentesque. Nec tortor odio elit tempus id. Facilisis erat velit aliquam pellentesque.
                                </p>
                                <div className="rounded-full h-24 w-24 overflow-hidden mx-auto">
                                    <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e81c87b8574e93e50eb3f6_pexels-photo-9771525.jpg'} className={'w-full h-full object-cover'} />
                                </div>
                                <p className="text-white text-xl font-press-start mt-3">MIKE MOORE</p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="flex justify-end max-md:pr-14">
                            <div className="w-[80%] md:w-[50%]  md:mx-auto text-center py-10 flex flex-col">
                                <p className="text-white text-sm md:text-xl font-press-start-lato my-10">
                                    Turpis phasellus mi vulputate lacinia in auctor egestas eget duis. Facilisi eget ac purus pellentesque. Nec tortor odio elit tempus id. Facilisis erat velit aliquam pellentesque.
                                </p>
                                <div className="rounded-full h-24 w-24 overflow-hidden mx-auto">
                                    <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/6200e298837ea90b43ffdf8f_pexels-ron-lach-9464249-p-500.jpeg'} className={'w-full h-full object-cover'} />
                                </div>
                                <p className="text-white text-xl font-press-start mt-3">JOHN
                                    CONSTANT</p>
                            </div>
                        </div>
                    </div>

                  

                </Slider>
            </div>
        </>
    )
}
export default HomeOpinion