import React from "react";
import HomeFirst from "./Components/homeFirst";
import HomeImg from "./Components/homeImg";
import HomeOpinion from "./Components/homeOpinion";
import HomeService from "./Components/homeService";
import HomeTabs from "./Components/homeTabs";
import HomeTeam from "./Components/homeTeam";

function Home() {
    return (
        <>
           <HomeFirst />
           <HomeService />
           <HomeOpinion />
           <HomeTabs />
           <HomeImg />
           <HomeTeam />
        </>
    )
}

export default Home
