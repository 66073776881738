import React from "react";
import "../../assets/js/script";

function HomeImg() {

    return (
        <>
            <div className="border" id="Home__Gallery">
                <div className="my-10">
                    <div className="w-[220px] h-[300px] md:w-[300px] md:h-[400px] xl:w-[400px]  xl:h-[500px] mx-auto relative max-lg:overflow-hidden">
                        <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa208318a52e8b28017f9f_pexels-ron-lach-9466719-p-1080.jpeg'} className={'absolute z-0 bottom-20 origin-bottom-left'} id={'js-logo'} />
                        <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa202ca0aba7d06fc7fc60_pexels-ron-lach-9466710.jpg'} className={'absolute z-20 w-[300px] h-[400px] xl:w-[400px] xl:h-[500px]'} id={''} />
                        <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa2087aa7526496bc06dea_pexels-ron-lach-9467666-p-1080.jpeg'} className={'absolute z-0 bottom-20 origin-bottom-right'} id={'js-logo1'} />
                    </div>
                    <div className="w-[220px] md:w-[400px] mx-auto relative text-center text-xl uppercase py-5 text-white font-bold font-press-start mt-5">
                        A/B TESTING,WOLDIE
                    </div>
                </div>
                <div className="my-10">
                    <div className="w-[220px] h-[300px] md:w-[300px] md:h-[400px] xl:w-[400px]  xl:h-[500px] mx-auto relative max-lg:overflow-hidden">
                        <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa209e266fbad14a314605_pexels-ron-lach-9467665-p-800.jpeg'} className={'absolute z-0 bottom-20 origin-bottom-left'} id={'js-logo2'} />
                        <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa20969bcfb50f5601e6ae_pexels-ron-lach-9466720-p-1080.jpeg'} className={'absolute z-20 w-[300px] h-[400px] xl:w-[400px] xl:h-[500px]'} id={''} />
                        <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa209a0812d67d7c79fb32_pexels-ron-lach-9466767-p-500.jpeg'} className={'absolute z-0 bottom-20 origin-bottom-right'} id={'js-logo3'} />
                    </div>
                    <div className="w-[220px] md:w-[400px] mx-auto relative text-center text-xl uppercase py-5 text-white font-bold font-press-start mt-5">
                    RESPONSIVE DESIGN,TUTUM
                    </div>
                </div>
                
               
                {/* <div className="my-10">
                    <div className="w-[220px] h-[300px] md:w-[300px] md:h-[400px] xl:w-[400px]  xl:h-[500px] mx-auto relative max-lg:overflow-hidden">
                        <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/6200cc9aef437345b37db538_pexels-ron-lach-9467676-p-500.jpeg'} className={'absolute z-0 bottom-20 origin-bottom-left'} id={'js-logo4'} />
                        <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/6200cc870e2fff49c72ac53f_pexels-ron-lach-9466724-p-1080.jpeg'} className={'absolute z-20 w-[300px] h-[400px] xl:w-[400px] xl:h-[500px]'} id={''} />
                        <img src={'https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/6200cc9c873d7837fd26a9ac_pexels-ron-lach-9466769.jpg'} className={'absolute z-0 bottom-20 origin-bottom-right'} id={'js-logo5'} />
                    </div>
                    <div className="w-[220px] md:w-[400px] mx-auto relative text-center text-xl uppercase py-5 text-white font-bold font-press-start mt-5">
                        TRANSACTIONAL EMAIL CAMPAIGN,NYSKA
                    </div>
                </div> */}


            </div>

        </>
    )
}

export default HomeImg