import React from "react";
import Home from "./Pages/home";
import Header from "./Pages/Layouts/header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Route, Routes } from "react-router-dom";
import Footer from "./Pages/Layouts/footer";
import New from "./Pages/new";
function App() {

  return (
    <>
      {/* <div className="flex">
        <div className="w-[5%] border-r-2 border-white relative">
          <Header />
          <div className="max-sm:hidden text-white  box-border  top-24  fixed  z-50 h-[100%] font-press-start bg-[#111] tracking-widest flex  items-center justify-center w-[93px]" style={{ writingMode: 'vertical-lr', textOrientation: ' sideways  ' }}>WE ARE  VISUAL DESIGNERS AND DEVELOPERS</div>
        </div>
        <div className="w-[95%]">

       
          <Footer />
        </div>


      </div> */}
      {/* <Routes>
      <Route path="/new" element={<New />} />
      </Routes> */}

      <div className="flex">
        <div className="w-[60px]  md:w-[80px] z-50">
            <Header />
        </div>
        <div className="w-[calc(100%-60px)] md:w-[calc(100%-80px)]">
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/new" element={<New />} /> */}
          </Routes>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
