export default function TabsSmall(props) {
    return (
        <>

            <div className="w-[70%] sm:w-[80%] lg:w-[45%] group cursor-pointer my-20">
                <div className="w-full h-36 sm:h-48 overflow-hidden">
                    <img src={props.link} className={'w-full h-full object-cover transition-all duration-300 group-hover:scale-105'} />
                </div>
                <div className="mt-5">
                    <h1 className="font-press-start text-3xl uppercase">{props.title}</h1>
                </div>
            </div>

        </>
    )
}