import React from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TabsSmall from "./TabsSmall";
function HomeTabs() {
    
    const TabsContent1 = [
        {link:"https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa299b4728a68effa27f74_pexels-ron-lach-9466707-p-1080.jpeg" , title:" How to create a productive meeting environment"},
        {link:"https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa29a8b787b8d0a43dadc5_pexels-ron-lach-9467668-p-800.jpeg" , title:"Getting your team onboard a new app"},
        {link:"https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa29c018a52ec05a01bb63_pexels-ron-lach-9467685-p-1080.jpeg" , title:"5 Presentation tips for your next stand up"},
        {link:"https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa209a0812d67d7c79fb32_pexels-ron-lach-9466767-p-800.jpeg" , title:"10 Ways to protect your team from the pandemic"},
    ]
    const TabsContent2 = [
        {link:"https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/6211e85a3ab1f9060d5670c7_pexels-ron-lach-9466713%201-p-1080.jpeg" , title:"Marketing tool tips for creative directors"},
        {link:"https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/6211e87698b536221b7625ed_pexels-ron-lach-9466792%201-p-1080.jpeg" , title:"How to design packaging for your next product"},
        {link:"https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/6211e970361b9d59cc264292_pexels-ron-lach-9467690%201%20(1)-p-1080.jpeg" , title:"3 New apps to get your meeting moving"}
    ]
    const TabsContent3 = [
        {link:"https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/6211e84f0ba94d845e627ebd_pexels-ron-lach-9466709%201-p-1080.jpeg" , title:"Why is celebration good for team morale?"},
        {link:"https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/6200cc9c873d7837fd26a9ac_pexels-ron-lach-9466769-p-800.jpeg" , title:"How to nail your next pitch"},
        {link:"https://assets.website-files.com/61e8dec5b0c7ae7852c13e9c/61fa29b45ebb26fd577ca070_pexels-ron-lach-9467678-p-1080.jpeg" , title:"Branding strategies for taking your agency to the next level"}
    ]


    return (
        <>
            <div className="max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-2xl  mx-auto py-20  text-white" id="Home__Portfolio">
                <Tabs>
                    <TabList>
                        <Tab><span className="uppercase">ui/ux design</span></Tab>
                        <Tab><span className="uppercase">branding</span></Tab>
                        <Tab><span className="uppercase">web design</span></Tab>
                    </TabList>

                    <TabPanel>
                        <div className="py-10">
                            <div className="flex justify-center lg:justify-between flex-wrap lg:mt-20">

                              {
                                TabsContent1 && TabsContent1.map((item,index) => {
                                    return <TabsSmall link={item.link} title={item.title} />
                                })
                              }  

                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="py-10">
                            <div className="flex justify-center lg:justify-between flex-wrap lg:mt-20">
                            {
                                TabsContent2 && TabsContent2.map((item,index) => {
                                    return <TabsSmall link={item.link} title={item.title} />
                                })
                              }  
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="py-10">
                            <div className="flex justify-center lg:justify-between flex-wrap lg:mt-20">
                            {
                                TabsContent3 && TabsContent3.map((item,index) => {
                                    return <TabsSmall link={item.link} title={item.title} />
                                })
                              }  
                            </div>
                        </div>
                    </TabPanel>


                </Tabs>
            </div>
        </>
    )
}
export default HomeTabs