import React from "react";
import Img1 from "../../assets/img/Garou_avp_Iron_Man_3_Paris.jpg";
function HomeFirst() {
    return (
        <>

            <div className="" id="Home__First">
                <div className="sticky top-0 bg-[#111] z-50 max-sm:pl-24 p-4 pl-8 text-white  font-press-start text-lg sm:text-2xl border-b-2">
                    Mike
                </div>

                <div className="text-white flex flex-col md:flex-row">
                    <div className="w-full md:w-4/6 pt-4 flex flex-col justify-between">
                        <div className="pl-2 sm:pl-8">
                            <p className="text-xl sm:text-3xl lg:text-5xl 2xl:text-7xl font-press-start-lato mt-4">Actor , ShowMan , TikToker</p>
                            <h1 className="text-lg sm:text-2xl lg:text-4xl 2xl:text-7xl font-press-start-lato">Name LastName</h1>
                            <p className="text-sm sm:text-xl lg:text-3xl font-press-start-lato mt-4 w-[100%] lg:w-[70%] 2xl:w-[50%]">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.
                            </p>
                            <div className="my-16">
                                <a href="tel:+37490000000" className="border px-14 py-4 relative after:absolute after:w-full after:h-full after:bg-[#d3463f] after:top-0 after:left-0"><span className="relative z-10">CALL ME</span></a>
                            </div>
                        </div>
                        <div className="flex justify-end max-sm:flex-col max-sm:justify-center">
                            <div className="max-sm:p-4 sm:mx-10   sm:w-72  h-full lg:mt-20">
                                <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e81c87b8574e93e50eb3f6_pexels-photo-9771525.jpg'} className={'sm:w-full h-auto sm:h-96 object-cover'} />
                            </div>
                            <div className="max-sm:p-4 sm:mx-10   sm:w-72  h-full">
                                <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e81c872f9ddfa90c6b31d4_pexels-photo-9771504.jpg'} className={'w-full  h-96 object-cover'} />
                            </div>
                        </div>
                        <div className="border flex max-md:flex-col">
                            <div className=" md:w-1/5 text-center text-sm md:text-xl py-3 font-press-start-cardo uppercase">Text</div>
                            <div className=" md:w-1/5 text-center text-sm md:text-xl py-3 font-press-start-cardo uppercase">Text</div>
                            <div className=" md:w-1/5 text-center text-sm md:text-xl py-3 font-press-start-cardo uppercase">Text</div>
                            <div className=" md:w-1/5 text-center text-sm md:text-xl py-3 font-press-start-cardo uppercase">Text</div>
                            <div className=" md:w-1/5 text-center text-sm md:text-xl py-3 font-press-start-cardo uppercase">Text</div>
                        </div>
                    </div>
                    <div className="w-full md:w-2/6">
                        <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e81c8cae2934d2a2507027_pexels-photo-9771831%201-p-800.jpeg'} className={'max-md:hidden md:h-full'} />
                    </div>
                </div>

            </div>

        </>
    )
}

export default HomeFirst