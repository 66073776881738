import React, { useState } from "react";
import { Link } from "react-scroll";

function Header() {
  const [toggle, setToggle] = useState(false);
  const handleClick = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <div className="w-[60px] sm:w-[80px]  h-[100hv] sticky border-r-2 top-0 left-0">
        <div className="flex justify-center">
        <button onClick={handleClick} className={'h-16'}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-white">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>

        </button>
        </div>
        <div className={toggle ? 'menuActive' : 'menu'} id={'NavMenu'} >
          <ul className="flex flex-col space-y-5 p-4 sm:p-12 xl:p-12">
            <li>
              <Link className="Menu__Link__Menu" to="Home__First" spy={true} smooth={true} offset={50} duration={500}>HOME</Link>
            </li>
            <li>
              <Link className="Menu__Link__Menu" to="Home__Service" spy={true} smooth={true} offset={50} duration={500}>SERVICE</Link>
            </li>
            <li>
              <Link className="Menu__Link__Menu" to="Home__Opinion" spy={true} smooth={true} offset={50} duration={500}>OPINION</Link>
            </li>
            <li>
              <Link className="Menu__Link__Menu" to="Home__Portfolio" spy={true} smooth={true} offset={50} duration={500}>PORTFOLIO</Link>
            </li>
            <li>
              <Link className="Menu__Link__Menu" to="Home__Gallery" spy={true} smooth={true} offset={50} duration={500}>GALLERY</Link>
            </li>
            <li>
              <Link className="Menu__Link__Menu" to="Home__Team" spy={true} smooth={true} offset={50} duration={500}>TEAM</Link>
            </li>
          </ul>
          <div className="p-4 sm:p-12 xl:p-12 mt-12">
            <h1 className="text-white font-bold font-press-start-lato">SOCIAL MEDIA</h1>
            <ul className="flex mt-4">
              <li className="flex">
                <a href="" className="flex justify-center items-center">
                  <div className="mr-3 w-8 h-8  flex justify-center items-center relative group overflow-hidden cursor-pointer">
                    <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e8098f75d770fa12ea3e6e_Vector.svg'} className={'absolute transition-all  duration-300 translate-y-0 group-hover:-translate-y-7'} />
                    <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e8098f75d770fa12ea3e6e_Vector.svg'} className={'opacity-50 absolute translate-y-7 transition-all  duration-300 group-hover:translate-y-0'} />
                  </div>
                </a>
              </li>
              <li className="flex">
                <a href="" className="flex justify-center items-center">
                  <div className="mr-3 w-8 h-8  flex justify-center items-center relative group overflow-hidden cursor-pointer">
                    <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e8098f75d7705b5eea3e6f_5fc2d6c4e509f31bddd6ec13_5fb410854861edca9a030b2e_Group%209%201%201.svg'} className={'absolute transition-all  duration-300 translate-y-0 group-hover:-translate-y-7'} />
                    <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e8098f75d7705b5eea3e6f_5fc2d6c4e509f31bddd6ec13_5fb410854861edca9a030b2e_Group%209%201%201.svg'} className={'opacity-50 absolute translate-y-7 transition-all  duration-300 group-hover:translate-y-0'} />
                  </div>
                </a>
              </li>
              <li className="flex">
                <a href="" className="flex justify-center items-center">
                  <div className="mr-3 w-8 h-8  flex justify-center items-center relative group overflow-hidden cursor-pointer">
                    <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e8098f75d7701588ea3e6d_5fc2d6c451c3f8eaf374fb3b_5fb410854861ed692b030b2d_Group%2010%201%201.svg'} className={'absolute transition-all  duration-300 translate-y-0 group-hover:-translate-y-6'} />
                    <img src={'https://assets.website-files.com/61e620c2b90538c86c7a6513/61e8098f75d7701588ea3e6d_5fc2d6c451c3f8eaf374fb3b_5fb410854861ed692b030b2d_Group%2010%201%201.svg'} className={'opacity-50 absolute translate-y-6 transition-all  duration-300 group-hover:translate-y-0'} />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className=" text-white flex justify-center items-center w-full  h-full rotate-180" style={{writingMode: 'vertical-rl'}}>
        WE ARE  VISUAL DESIGNERS AND DEVELOPERS
        </div>
      
      </div>
    </>
  )
}

export default Header