import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
function HomeService() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows:false , 
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 970,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
        ]
    };
    return (
        <>
         
            <div className="" id="Home__Service">
            <Slider {...settings} className={'text-white'}>
                <div className="border">
                    <div className="p-4 sm:p-12 flex flex-col justify-center ">
                        <h1 className="text-2xl sm:text-4xl font-press-start">Service 1</h1>
                        <p className="font-press-start-lato text-sm sm:text-xl mt-6">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.
                        </p>
                        <div className="my-10">
                           <Link className="text-sm uppercase relative pb-3 after:transition-all after:duration-500 after:w-3 after:h-[2px] after:left-0 after:bottom-0 after:bg-white after:absolute hover:after:w-full">Read More</Link>  
                        </div>
                    </div>
                </div>
                <div className="border">
                    <div className="p-4 sm:p-12 flex flex-col justify-center ">
                        <h1 className="text-2xl sm:text-4xl font-press-start">Service 1</h1>
                        <p className="font-press-start-lato text-sm sm:text-xl mt-6">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.
                        </p>
                        <div className="my-10">
                           <Link className="text-sm uppercase relative pb-3 after:transition-all after:duration-500 after:w-3 after:h-[2px] after:left-0 after:bottom-0 after:bg-white after:absolute hover:after:w-full">Read More</Link>  
                        </div>
                    </div>
                </div>
                <div className="border">
                    <div className="p-4 sm:p-12 flex flex-col justify-center ">
                        <h1 className="text-2xl sm:text-4xl font-press-start">Service 1</h1>
                        <p className="font-press-start-lato text-sm sm:text-xl mt-6">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.
                        </p>
                        <div className="my-10">
                           <Link className="text-sm uppercase relative pb-3 after:transition-all after:duration-500 after:w-3 after:h-[2px] after:left-0 after:bottom-0 after:bg-white after:absolute hover:after:w-full">Read More</Link>  
                        </div>
                    </div>
                </div>
                <div className="border">
                    <div className="p-4 sm:p-12 flex flex-col justify-center ">
                        <h1 className="text-2xl sm:text-4xl font-press-start">Service 1</h1>
                        <p className="font-press-start-lato text-sm sm:text-xl mt-6">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.
                        </p>
                        <div className="my-10">
                           <Link className="text-sm uppercase relative pb-3 after:transition-all after:duration-500 after:w-3 after:h-[2px] after:left-0 after:bottom-0 after:bg-white after:absolute hover:after:w-full">Read More</Link>  
                        </div>
                    </div>
                </div>
                <div className="border">
                    <div className="p-4 sm:p-12 flex flex-col justify-center ">
                        <h1 className="text-2xl sm:text-4xl font-press-start">Service 1</h1>
                        <p className="font-press-start-lato text-sm sm:text-xl mt-6">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.
                        </p>
                        <div className="my-10">
                           <Link className="text-sm uppercase relative pb-3 after:transition-all after:duration-500 after:w-3 after:h-[2px] after:left-0 after:bottom-0 after:bg-white after:absolute hover:after:w-full">Read More</Link>  
                        </div>
                    </div>
                </div>
                <div className="border">
                    <div className="p-4 sm:p-12 flex flex-col justify-center ">
                        <h1 className="text-2xl sm:text-4xl font-press-start">Service 1</h1>
                        <p className="font-press-start-lato text-sm sm:text-xl mt-6">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.
                        </p>
                        <div className="my-10">
                           <Link className="text-sm uppercase relative pb-3 after:transition-all after:duration-500 after:w-3 after:h-[2px] after:left-0 after:bottom-0 after:bg-white after:absolute hover:after:w-full">Read More</Link>  
                        </div>
                    </div>
                </div>
                <div className="border">
                    <div className="p-4 sm:p-12 flex flex-col justify-center ">
                        <h1 className="text-2xl sm:text-4xl font-press-start">Service 1</h1>
                        <p className="font-press-start-lato text-sm sm:text-xl mt-6">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.
                        </p>
                        <div className="my-10">
                           <Link className="text-sm uppercase relative pb-3 after:transition-all after:duration-500 after:w-3 after:h-[2px] after:left-0 after:bottom-0 after:bg-white after:absolute hover:after:w-full">Read More</Link>  
                        </div>
                    </div>
                </div>
                <div className="border">
                    <div className="p-4 sm:p-12 flex flex-col justify-center ">
                        <h1 className="text-2xl sm:text-4xl font-press-start">Service 1</h1>
                        <p className="font-press-start-lato text-sm sm:text-xl mt-6">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.
                        </p>
                        <div className="my-10">
                           <Link className="text-sm uppercase relative pb-3 after:transition-all after:duration-500 after:w-3 after:h-[2px] after:left-0 after:bottom-0 after:bg-white after:absolute hover:after:w-full">Read More</Link>  
                        </div>
                    </div>
                </div>
                
            </Slider>
            </div>

        </>
    )
}


export default HomeService